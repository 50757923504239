import Raicon from 'raicon';
import DropZoneForm from '@/layouts/application/utils/dropzone/dropZoneForm';
import { initSelect2 } from '@/layouts/application/packages/select2';

class AdminMarketplacesController {
  constructor() {
    this.bannerImageSelector = '#banner-image';
    this.imagePreviewSelector = '.image-preview';
    this.select2Redeemed = '#select2-redeemed';
    this.radioRedeemed = 'input[name="marketplace[all_clients]"]';
    this.redeededForm = '#form-redeemed';
    this.marketplaceIdsInput = '#marketplace_ids';
    this.marketplaceForm = '#new_marketplace';
  }

  index() {
    this.initDropZone();
    this.handleAjaxSelect2();
    this.toggleBulkRedeemed();
    this.toggleTargetClients();
  }

  setUp() {
    this.handleImageChange();
    this.handleAjaxSelect2();
    this.toggleTargetClients()
    this.handleSelectZoomType()
    initSelect2();
  }

  show() {}

  new() {
    this.setUp()
  }

  create() {
    this.setUp()
  }

  edit() {
    this.setUp()
  }

  update() {
    this.setUp()
  }

  toggleTargetClients(){
    $(this.radioRedeemed).on('change', ( { target } ) => {
      let value = target.value;

      if(value == 'true'){
        $(this.select2Redeemed).attr('disabled', 'disabled')
      } else {
        $(this.select2Redeemed).removeAttr('disabled')
      }
    })
  }

  toggleBulkRedeemed(){
    $(this.redeededForm).addClass('d-none');
    $('.select-box').on('change', (e) => {
      const _this = $(e.target);
      const currentMarketplaceIdsInput = $(this.marketplaceIdsInput).val().split(',');
      if(_this.is(':checked')){
        $(this.redeededForm).removeClass('d-none');
        currentMarketplaceIdsInput.push(_this.data('value'))
        $(this.marketplaceIdsInput).val(currentMarketplaceIdsInput.join(','))
      } else {
        if($('.select-box:checked').length == 0){
          $(this.redeededForm).addClass('d-none');
        }

        const marketplaceIdsValue = currentMarketplaceIdsInput.filter(x => x != _this.data('value'))
        $(this.marketplaceIdsInput).val(marketplaceIdsValue.join(','))
      }
    })
  }

  handleAjaxSelect2(){
    $('#select2-redeemed').select2({
      ajax: {
        delay: 250,
        url: Routes.consultant_clients_path,
        dataType: 'json',
        data: function(params) {
          const query = {
            search: {
              q: params.term
            }
          }

          return query;
        },
        processResults: function(data) {
          const modifyData = data.clients.map((client) => {
            return {
              id: client.id,
              text: `${client.first_name} ${client.last_name} (${client.email})`
            }
          })

          return {
            results: modifyData
          }
        }
      }
    })
  }

  handleImageChange() {
    $(this.bannerImageSelector).on('change', (e) => {
      const file = e.target.files[0];
      if (!file) return;

      const white_list = ['image/jpeg', 'image/png', 'image/jpg'];

      if (!white_list.includes(file.type)) {
        alert("You can't upload files of this type.");
        $(e.target).val('');
        $(this.imagePreviewSelector).removeAttr('src');
        return;
      }
      $(this.imagePreviewSelector).attr('src', URL.createObjectURL(file));
    });
  }

  handleSelectZoomType() {
    $(document).ready(() => {
      this.toggleSelectOptionItemType();

      $('#select2-item-type').on('change', () => {
        this.toggleSelectOptionItemType();
      });

      $('#marketplace_requires_user_consent_true').on('click', () => {
        $('#consent-text').removeClass('d-none');
      });

      $('#marketplace_requires_user_consent_false').on('click', () => {
        $('#consent-text').addClass('d-none');
      });
    });
  }

  toggleSelectOptionItemType() {
    let selectedValue = $('#select2-item-type').val();

    let zoomWebinarId = $('#zoom-webinar-id');
    let allowMultipleRedeem = $('#allow-multiple-redeem');
    let consentText = $('#consent-text');
    let requireUserConsent = $('#require-user-consent');
    let exceptDynamicLink = $('#except-dynamic-link');
    let dynamicLink = $('#dynamic-link');

    if (selectedValue === 'zoom_webinar') {
      zoomWebinarId.removeClass('d-none');
    } else {
      zoomWebinarId.addClass('d-none');
    }

    if (selectedValue === 'dynamic_link') {
      dynamicLink.removeClass('d-none');
      exceptDynamicLink.addClass('d-none');
    } else {
      dynamicLink.addClass('d-none');
      exceptDynamicLink.removeClass('d-none');
    }

    if (selectedValue === 'internal' || selectedValue === 'zoom_webinar') {
      allowMultipleRedeem.removeClass('d-none');
      requireUserConsent.removeClass('d-none');
      if ($('#marketplace_requires_user_consent_false').is(':checked')) {
        consentText.addClass('d-none');
      }

      if ($('#marketplace_requires_user_consent_true').is(':checked')) {
        consentText.removeClass('d-none');
      }
    } else {
      allowMultipleRedeem.addClass('d-none');
      requireUserConsent.addClass('d-none');
      consentText.addClass('d-none');
    }
  }

  initDropZone() {
    new DropZoneForm({
      isLoading: true,
      isFailedRecordsLink: true,
      overrideInput: '.override-duplication',
      formSelector: '#import-dynamic-link-form',
      submitBtnSelector: '#submit-import-dynamic-link',
      fileParamName: 'dynamic_link[dynamic_link_file]',
      importResultFormSelector: '#import-dynamic-link-result-form',
      importBtnSelector: [
        '#import-dynamic-link-form',
        '#import-dynamic-link-button'
      ],
    }).setup();
  }
}

Raicon.register('admin/marketplaces', AdminMarketplacesController);
