import Raicon from 'raicon';
import handleSideMenu from '../shared/handleSideMenu';
import handleUploadAvatar from '../shared/handleUploadAvatar';
import {
  emailFormatValidator,
  presentValidator,
  phoneNumberFormatValidator
} from '@/layouts/application/utils/validators';

class ClientsMarketplacesController {
  beforeEach() {
    handleSideMenu();
    handleUploadAvatar();
  }

  index() {
    this.getMarketplaceList();
    this.scrollToTop()
  }

  getMarketplaceList() {
    $(".marketplace-item").click(function (e) {
      e.preventDefault()
      $('.marketplace-item').removeClass('activated');
      $(e.currentTarget).addClass('activated');

      const categoryId = e.currentTarget.dataset.categoryId
      $.ajax({
        url: Routes.marketplace_list_clients_marketplaces_path({ category_id: categoryId }),
        type: 'GET',
        success: (response) => {
          $("#marketplace-list-items").html(response)
        },
        error: (xhr, status, error) => {
          console.log(error)
        }
      });
    });
  }

  getRedeemMarketplace() {
    $(document).ready(() => {
      let submitButton = $('input[type="submit"]');
      let acknowledgeButton = $('#consent_given');
      if (submitButton.val() !== 'Redeemed') {
        this.checkAcknowledgeButton();

        $('.client-marketplace .simple_form').on('click keyup', (e) => {
          let currentStepValidationPassed = true;
          const requiredFields = [
            {
              selector: '#user_email',
              validators: [presentValidator, emailFormatValidator],
            },
            {
              selector: '#user_phone_number',
              validators: [presentValidator, phoneNumberFormatValidator],
            },
            {
              selector: '#user_first_name',
              validators: [presentValidator],
            },
            {
              selector: '#user_last_name',
              validators: [presentValidator],
            },
          ];

          requiredFields.forEach(function (fieldInfor) {
            const fieldInput = $(fieldInfor.selector);
            let isValidField = true;

            fieldInfor.validators.forEach(function (validatorMethod) {
              isValidField = validatorMethod(fieldInput.val());

              if (!isValidField) {
                currentStepValidationPassed = false;
                fieldInput.addClass('is-invalid').removeClass('is-valid');
                fieldInput.parent().parent().find('.invalid-feedback').show();
              } else {
                fieldInput.addClass('is-valid').removeClass('is-invalid');
                fieldInput.parent().parent().find('.invalid-feedback').hide();
              }
            });
          });

          if (currentStepValidationPassed && (acknowledgeButton.is(':checked') || acknowledgeButton.length === 0)) {
            submitButton.prop('disabled', false);
          } else {
            submitButton.prop('disabled', true);
          }
        });
      }
    });
  }

  checkAcknowledgeButton() {
    let submitButton = $('input[type="submit"]');
    let acknowledgeButton = $('#consent_given');
    if (acknowledgeButton.is(':checked') || acknowledgeButton.length == 0) {
      submitButton.prop('disabled', false);
    } else {
      submitButton.prop('disabled', true);
    }
  }

  scrollToTop() {
    $('#backToTop').hide();

    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        $('#backToTop').fadeIn();
      } else {
        $('#backToTop').fadeOut();
      }
    });

    $('#backToTop').click(function () {
      $('html, body').animate({ scrollTop: 0 }, 'smooth');
      return false;
    });
  }
}

Raicon.register('clients/marketplaces', ClientsMarketplacesController)
